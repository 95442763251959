@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
body,
html {
  font-family: "Roboto", sans-serif;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

#root {
  font-family: "Roboto", sans-serif;
  height: -webkit-fill-available;
}

// SCROLLBAR
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e3e3e3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #838383;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #009a49;
}
